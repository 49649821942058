<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t('menu.season') }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="seasons"
      :search="search"
      v-model="selectedItems"
      :show-select="showCheckBox"
      :options.sync="options"
      :server-items-length="totalFromServer"
      :loading="loading"
      checkbox-color="primary"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="12" xl="4">
              <v-text-field
                onkeypress="return (event.charCode != 34)"
                v-on:keyup.86="replaceSearch"
                v-on:keyup.ctrl.86="replaceSearch"
                dense
                filled
                rounded
                color="primary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('general.titles.filter')"
                :clearable="true"
                @keypress.enter="searchForText"
                @click:clear="searchAll"
                @click:append="searchForText"
                single-line
                hide-details
              />
            </v-col>
            <v-col lg="4" md="4" sm="12" xl="4">
              <v-btn
                small
                tile
                :elevation="0"
                color="gray"
                style="border-radius: 1.5em"
                @click="checkBoxShow"
                class="mr-1"
                :disabled="!(seasons && seasons.length > 0)"
              >
                <span v-if="!showCheckBox">
                  <v-icon small color="neuter" left
                    >mdi-checkbox-multiple-marked-outline</v-icon
                  >
                  <span class="neuter--text">{{
                    $t('general.buttons.checksBoxEnable')
                  }}</span>
                </span>
                <span v-else>
                  <v-icon small color="neuter" left
                    >mdi-checkbox-multiple-marked</v-icon
                  >
                  <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
                </span>
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              lg="4"
              md="4"
              sm="8"
              xl="4"
              class="text-end d-flex justify-end"
            >
              <v-dialog persistent v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="gray"
                    style="border-radius: 1.5em 0 0 1.5em"
                    @click="exportCSVSeasons"
                    v-if="check([{ domain: 'Seasons', permisions: ['Write'] }])"
                    :loading="loadingExport"
                    class="mr-1"
                  >
                    <v-icon small color="neuter" left
                      >mdi-download-multiple
                    </v-icon>
                    <span class="neuter--text">{{
                      $t('general.buttons.export')
                    }}</span>
                    <template v-slot:loader>
                      <v-progress-circular
                        style="
                          position: absolute;
                          margin-left: -3px;
                          height: 15px;
                        "
                        color="secondary accent-4"
                        indeterminate
                        rounded
                      />
                    </template>
                  </v-btn>
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="gray"
                    style="border-radius: 0 1.5em 1.5em 0"
                    v-if="check([{ domain: 'Season', permisions: ['Write'] }])"
                    v-on="on"
                  >
                    <v-icon small color="neuter" left>mdi-plus</v-icon>
                    {{ $t('general.buttons.new') }}
                  </v-btn>
                </template>
                <v-card :light="true" color="white">
                  <v-card-title class="pa-6">
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="pa-6">
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              outlined
                              dense
                              onkeypress="return (event.charCode != 34)"
                              v-on:keyup.86="replaceSeasonName"
                              v-on:keyup.ctrl.86="replaceSeasonName"
                              color="primary"
                              v-model="editedItem.season_name"
                              required
                              class="required"
                              :rules="requireAndMaxRules(max)"
                              :counter="max"
                              maxlength="max"
                            >
                              <template v-slot:label>
                                {{ $t('season.fields.name') }}
                                <strong class="red--text">*</strong>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              outlined
                              dense
                              :no-data-text="
                                $t('general.fields.noDataAvailable')
                              "
                              clearable
                              color="primary"
                              item-color="primary"
                              hide-selected
                              :items="orderDatas(products, 'product_name')"
                              :rules="selectRule"
                              class="required"
                              item-value="id"
                              return-object
                              item-text="product_name"
                              v-model="editedItem.product"
                              small-chips
                              @keypress.enter="searchProduct($event)"
                            >
                              <template v-slot:label>
                                {{ $t('season.fields.cultura') }}
                                <strong class="red--text">*</strong>
                              </template>
                              <template v-slot:prepend-item>
                                <v-alert
                                  color="primary"
                                  type="info"
                                  dense
                                  border="right"
                                  text
                                  tile
                                  class="ma-0"
                                >
                                  <template #append>
                                    <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                                  </template>
                                  <template #prepend>
                                    <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                                  </template>
                                  {{ $t('general.fields.startTyping') }}
                                </v-alert>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-menu
                              v-model="refers['startDateTime']"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              color="primary"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                  outlined
                                  dense
                                  :no-data-text="$t('general.fields.noDataAvailable')"
                                  required
                                  class="required"
                                  :rules="requireRules"
                                  hide-details
                                  v-model="computedStartDateTime"
                                  :label="$t('season.fields.startDateTime')"
                                  small-chips
                                  v-bind="attrs"
                                  v-on="on"
                                  color="primary"
                                  append-icon="mdi-calendar-text"
                                />
                              </template>
                              <v-date-picker
                                :locale="$i18n.locale"
                                color="primary"
                                v-model="editedItem.startDateTime"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  text
                                  color="primary"
                                  @click="confirmDate('startDateTime')"
                                >
                                  {{ $t('general.buttons.confirm') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-menu
                              v-model="refers['endDateTime']"
                              :ref="refers['endDateTime']"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                              color="primary"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                  outlined
                                  dense
                                  :no-data-text="$t('general.fields.noDataAvailable')"
                                  required
                                  class="required"
                                  :rules="requireRules"
                                  hide-details
                                  v-model="computedEndDateTime"
                                  :label="$t('season.fields.endDateTime')"
                                  small-chips
                                  v-on="on"
                                  v-bind="attrs"
                                  color="primary"
                                  append-icon="mdi-calendar-text"
                                />
                              </template>
                              <v-date-picker
                                :locale="$i18n.locale"
                                color="primary"
                                v-model="editedItem.endDateTime"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  small
                                  text
                                  color="primary"
                                  @click="confirmDate('endDateTime')"
                                >
                                  {{ $t('general.buttons.confirm') }}
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              outlined
                              dense
                              :no-data-text="
                                $t('general.fields.noDataAvailable')
                              "
                              :clearable="true"
                              small
                              color="primary"
                              :label="$t('season.fields.locations')"
                              :items="orderDatas(locations, 'name')"
                              item-value="id"
                              :rules="requireRules"
                              class="required"
                              item-text="name"
                              persistent-hint
                              v-model="editedItem.primaryLocation"
                              small-chips
                              :loading="loadings.primary"
                              @click:clear="clearLocations"
                              @change="
                                fetchStorages({
                                primaryLocationVersionIds: editedItem.primaryLocation,
                                isActive: true
                              })"
                              @keypress.enter="
                                fetchLocations({
                                stringFilter: $event.target.value,
                                isActive: true
                              })"
                            >
                              <template v-slot:label>
                                {{ $t('season.fields.locations') }}
                                <span class="red--text"
                                  ><strong>*</strong></span
                                >
                              </template>
                              <template v-slot:prepend-item>
                                <v-alert
                                  color="primary"
                                  type="info"
                                  dense
                                  border="right"
                                  text
                                  tile
                                  class="ma-0"
                                >
                                  <template #append>
                                    <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                                  </template>
                                  <template #prepend>
                                    <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                                  </template>
                                  {{ $t('general.fields.startTyping') }}
                                </v-alert>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-autocomplete
                              outlined
                              dense
                              :no-data-text="
                                $t('general.fields.noDataAvailable')
                              "
                              ref="localizationFilters"
                              small
                              color="primary"
                              :label="$t('season.fields.storage_device')"
                              multiple
                              clearabled
                              :disabled="!editedItem.primaryLocation"
                              item-color="primary"
                              item-text="control_label"
                              :loading="loadings.secondary"
                              :items="
                                orderDatas(storages, 'control_label')
                              "
                              return-object
                              v-model="editedItem.storageDevices"
                              small-chips
                              :rules="selectRule"
                              @mouseup="
                                checkState(
                                  'localization',
                                  $refs.localizationFilters.filteredItems,
                                  editedItem.storageDevices
                                )
                              "
                              @keypress.enter="
                                fetchStorages({
                                primaryLocationVersionIds: editedItem.primaryLocation,
                                isActive: true,
                                stringFilter: $event.target.value
                              })"
                              @click:clear="checkboxState.localization = false"
                            >
                              <template v-slot:label>
                                {{ $t('season.fields.storage_device') }}
                                <strong class="red--text">*</strong>
                              </template>
                              <template v-slot:selection="{ item, index }">
                                <v-chip small-chips v-if="index === 0">
                                  <span>{{ item.control_label }}</span>
                                </v-chip>
                                <span
                                  v-if="index === 1"
                                  class="grey--text caption"
                                  >(+{{ editedItem.storageDevices.length - 1 }}
                                  {{ $t('general.titles.others') }})</span
                                >
                              </template>
                              <template v-slot:prepend-item>
                                <v-alert
                                  color="primary"
                                  type="info"
                                  dense
                                  border="right"
                                  text
                                  tile
                                  class="ma-0"
                                >
                                  <template #append>
                                    <v-icon color="primary" v-text="'mdi-keyboard-return'"/>
                                  </template>
                                  <template #prepend>
                                    <v-icon class="mr-2" color="primary" v-text="'mdi-information-slab-circle-outline'"/>
                                  </template>
                                  {{ $t('general.fields.startTyping') }}
                                </v-alert>
                                <v-divider class="my-2"></v-divider>
                                <v-checkbox
                                  v-model="checkboxState.localization"
                                  color="primary"
                                  class="pt-2 pb-2 mt-0 pl-4"
                                  :hide-details="true"
                                  @change="
                                    editedItem.storageDevices = selectAll(
                                      'localization',
                                      editedItem.storageDevices,
                                      $refs.localizationFilters.filteredItems,
                                      checkboxState.localization
                                    )
                                  "
                                >
                                  <template v-slot:label>
                                    <v-btn small color="primary" x-small text>
                                      {{ $t('general.titles.selectAll') }}
                                    </v-btn>
                                  </template>
                                </v-checkbox>
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <upload-evidence :nomenclator="editedItem" :loading="loadingEvidences" />
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="pa-6">
                    <v-spacer></v-spacer>
                    <v-btn small text color="neuter" @click="close">
                      {{ $t('general.buttons.cancel') }}
                    </v-btn>
                    <v-btn
                      small elevation="0" color="primary" class="t-bw-primary--text"
                      :disabled="!valid"
                      @click="save"
                    >
                      {{ $t('general.buttons.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="showItem(item)"
              v-if="check([{ domain: 'Season', permisions: ['Update'] }])"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.show') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.season') }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Season', permisions: ['Update'] }])"
              color="neuter"
              class="mr-2"
              small
              @click.stop.prevent="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.edit') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.season') }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              v-if="check([{ domain: 'Season', permisions: ['Delete'] }])"
              color="neuter"
              small
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t('general.buttons.delete') }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t('menu.season') }}
            </span>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-icon class="ml-6" color="success" v-if="item.active"
          >mdi-checkbox-marked-circle
        </v-icon>
        <v-icon class="ml-6" color="disabled" v-if="!item.active"
          >mdi-close-circle
        </v-icon>
      </template>
      <template v-slot:item.startDateTime="{ item }">
        <span>{{ getDateFormat(item.startDateTime) }}</span>
      </template>
      <template v-slot:item.endDateTime="{ item }">
        <span>{{ getDateFormat(item.endDateTime) }}</span>
      </template>
      <template v-slot:item.storage_device_names="{ item }">
        <ShowMoreComponent
          :item="item"
          secondary-field="storage_device_names"
        />
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog
        v-if="editedItem"
        v-model="showItemDetailsDialog"
        persistent
        width="600px"
        scrollable
      >
        <v-form ref="form" v-model="valid">
          <v-card>
            <v-card-title class="px-6">
              {{ editedItem.season_name }}
            </v-card-title>

            <v-divider light></v-divider>
            <v-card-text class="pa-6">
              <div class="row">
                <div class="col-12">
                  <div class="primary"
                    style="
                      border-radius: 5px;
                      padding: 7px;
                      text-align: start;
                    "
                  >
                    <h3 style="padding-left: 5px" class="t-bw-primary--text">
                      {{ $t('season.fields.cultura') }}
                    </h3>
                  </div>
                  <div class="mt-2 dialog-box__content">
                    <p v-if="selectedItem" style="display: contents">
                      {{ selectedItem.product_name }}
                    </p>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="primary"
                    style="
                      border-radius: 5px;
                      padding: 7px;
                      text-align: start;
                    "
                  >
                    <h3 style="padding-left: 5px" class="t-bw-primary--text">
                      {{ $t('season.fields.startDateTime') }}
                    </h3>
                  </div>
                  <div class="mt-2 dialog-box__content">
                    {{ computedStartDateTime }}
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="primary "
                    style="
                      border-radius: 5px;
                      padding: 7px;
                      text-align: start;
                    "
                  >
                    <h3 style="padding-left: 5px" class="t-bw-primary--text">
                      {{ $t('season.fields.endDateTime') }}
                    </h3>
                  </div>
                  <div
                    class="mt-2 dialog-box__content"
                  >
                    {{ computedEndDateTime }}
                  </div>
                </div>

                <div class="col-12">
                  <div class="primary "
                    style="
                      border-radius: 5px;
                      padding: 7px;
                      text-align: start;
                    "
                  >
                    <h3 style="padding-left: 5px" class="t-bw-primary--text">
                      {{ $t('season.fields.localization') }}
                    </h3>
                  </div>
                  <div class="mt-2 dialog-box__content">
                    <p
                      v-if="selectedItem"
                      v-for="(
                          storageDevice, index
                        ) in editedItem.storageDevices"
                      :key="index"
                    >
                      {{ storageDevice.control_label }}
                    </p>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-divider light></v-divider>
            <v-card-actions class="pa-6">
              <v-btn
                icon
                fab
                v-if="editedItem.evidences && editedItem.evidences.length > 0"
                text
                :loading="loadingDownloadEvidences"
                :disabled="loadingDownloadEvidences"
                small
                @click="downloadEvidence(editedItem)"
              >
                <v-icon color="neuter"> mdi-file-download-outline </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="t-bw-primary--text"
                     elevation="0"
                     color="primary"
                     small
                     @click="close">
                {{ $t('general.buttons.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{ title }}</v-card-title>
          <v-card-text class="pa-6">{{ dialogText }}</v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn small text color="neuter" @click="dialogClose">
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn small elevation="0" color="error" class="t-bw-error--text" @click="deleteItem">
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'
  import moment from 'moment'
  import _ from 'lodash'
  import LanguajeService from '@/services/LanguajeService'
  import PermisionService from '@/services/PermisionService'
  import SeasonsService from '@/services/SeasonsService'
  import UploadEvidence from '@/components/UploadEvidence'
  import sortList from '@/services/OrderListService'
  import ShowMoreComponent from '@/components/ShowMoreComponent'
  import {locationMixin} from "@/mixins/location.mixin";
  import {storageMixin} from "@/mixins/storage.mixin";

  const PROFILE = JSON.parse(localStorage.getItem('profile'));

  export default {
    components: { ShowMoreComponent, UploadEvidence },
    data: () => ({
      datetime: {
        start: null,
        end: null,
      },
      menu: false,
      langs: [],
      profile: null,
      dialog: false,
      editedIndex: -1,
      search: '',
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      refers: {
        startDateTime: '',
        endDateTime: '',
      },
      checkboxState: {
        product: false,
        localization: false,
      },
      editedItem: {
        season_name: '',
        product_name: '',
        product: {},
        startDateTime: '',
        endDateTime: '',
        storageDevices: [],
        primaryLocation: null,
        active: true,
        evidences: [],
      },
      defaultItem: {
        active: true,
        startDateTime: '',
        endDateTime: '',
        storageDevices: [],
      },
      searchStorageInput: '',
      searchProductInput: '',
      location_origin: null,
      showItemDetailsDialog: false,
      showDialog: false,
      title: 'Información',
      dialogText: 'Añada un texto a este dialogo',
      valid: false,
      max: 60,
      deletingItem: {},
      selectedItems: [],
      selectedItem: {},
      isEnabledCheckBox: false,
      showCheckBox: false,
      loadingEvidences: false,
      loadingDownloadEvidences: false,
      loadingExport: false,
      loadings: {
        products: false,
        primary: false,
        secondary: false,
      },
      options: {},
      defaultFilters: {
        companyId: PROFILE.company_id,
        search: "",
        pageableDTO: {
          page: 0,
          size: 10,
          sortBy: 'id',
          direction: 'DESC',
        }
      },
      storageLoading: false
    }),

    mixins: [locationMixin, storageMixin],

    computed: {
      notification: {
        get() {
          return this.$store.getters['ui/GET_NOTIFICATION']
        },
        set(val) {
          this.$store.commit('ui/NOTIFICATION', val)
        },
      },
      ...mapGetters({
        seasons: 'season/seasonsList',
        totalFromServer: "season/totalFromServer",
        evidences: 'documents/evidences',
        products: 'product/products',
        requireAndMaxRules: 'general/requireAndMaxRules',
        requireRules: 'general/requireRules',
        selectRule: 'general/selectRule',
        languageStructure: 'general/languageStructure',
        equalObjectsCompareRule: 'general/equalObjectsCompareRule',
      }),
      loading: {
        get() {
          return this.$store.getters['season/loading'];
        },
        set(val) {
          this.$store.commit('season/SET_LOADING', val)
        }
      },
      locationsFiltered() {
        return this.getLocationsByAction()
      },
      headers: () => [
        { text: i18n.t('season.fields.name'), value: 'season_name', sortable: false },
        { text: i18n.t('season.fields.cultura'), value: 'product_name', sortable: false },
        { text: i18n.t('season.fields.startDateTime'), value: 'startDateTime' },
        { text: i18n.t('season.fields.endDateTime'), value: 'endDateTime' },
        {
          text: i18n.t('season.fields.localization'), value: 'storage_device_names', sortable: false
        },
        {
          text: i18n.t('general.fields.action'),
          value: 'action',
          width: 120,
          sortable: false,
          align: 'center',
        },
      ],
      formTitle() {
        return (
          (this.editedIndex === -1
            ? i18n.t('general.titles.new')
            : i18n.t('general.titles.edit')) +
          ' ' +
          i18n.t('season.titles.new_season')
        )
      },
      computedStartDateTime: {
        get() {
          if (this.editedIndex !== -1) {
            this.editedItem.startDateTime =
              this.editedItem.startDateTime.substr(0, 10)
          }
          return this.editedItem.startDateTime
            ? moment(this.editedItem.startDateTime).format('DD / MM / YYYY')
            : ''
        },
        set() {
          return moment(new Date().toISOString().substr(0, 10)).format(
            'DD / MM / YYYY'
          )
        },
      },
      computedEndDateTime: {
        get() {
          if (this.editedIndex !== -1) {
            this.editedItem.endDateTime = this.editedItem.endDateTime.substr(
              0,
              10
            )
          }
          return this.editedItem.endDateTime
            ? moment(this.editedItem.endDateTime).format('DD / MM / YYYY')
            : ''
        },
        set() {
          return moment(new Date().toISOString().substr(0, 10)).format(
            'DD / MM / YYYY'
          )
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters['ui/GET_OPTIONS_TABLE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
        },
      },
    },

    created() {
      this.initialize()
    },

    watch: {
      options: {
        async handler() {
          this.optionsTable = this.options
          await this.searchText()
        },
        deep: true,
      },
    },

    methods: {
      ...mapActions({
        fetchListSeasons: 'season/fetchListSeasons',
        saveSeason: 'season/saveSeason',
        fetchListProducts: 'product/fetchListProduct',
        deleteSeason: 'season/deleteSeason',
        clearEvidences: 'documents/clearEvidences',
        exportCSV: 'season/exportCSV',
        fetchPrimaryLocations: "locations/fetchFilteredLocations",
        fetchStorageLocations: "storage/fetchFilteredStorages",
      }),
      async fetchLocations(filters) {
        if (filters) {
          this.$set(this.editedItem, "primaryLocation", null)

          this.$set(this.loadings, "primary", true)

          await this.fetchPrimaryLocations([
            filters,
            this.$toast,
          ]).finally(() => {
            this.$set(this.loadings, "primary", false)
          })
        }
      },
      async fetchStorages(filters, event) {
        if (event) {
          event.preventDefault();
        }

        if (filters && filters.primaryLocationVersionIds) {
          this.$set(this.editedItem, 'storageDevices', [])
          this.$set(this.loadings, 'secondary', true)

          if (typeof filters.primaryLocationVersionIds === 'object')
            filters.primaryLocationVersionIds = [filters.primaryLocationVersionIds.id]
          else
            filters.primaryLocationVersionIds = [filters.primaryLocationVersionIds]

          for (let property in filters) {
            if (filters[property] === null) {
              delete filters[property]
            }
          }

          await this.fetchStorageLocations([
            filters,
            this.$toast,
          ]).finally(() => {
            this.$set(this.loadings, 'secondary', false)
          })
        }
      },
      async searchAll() {
        let filters = {
          companyId: null,
          search: null,
          pageableDTO: {
            page: 0,
            size: 10,
            sortBy: 'id',
            direction: 'DESC',
          }
        }

        filters.companyId = this.profile.company_id

        await this.fetchListSeasons([filters, this.$toast])
      },
      async searchForText() {
        this.options.page = 1
        await this.searchText()
      },
      async searchText() {
        let filters = {
          companyId: null,
          search: null,
          pageableDTO: {
            page: null,
            size: null,
            sortBy: 'id',
            direction: 'DESC'
          }
        }

        filters.companyId = this.profile.company_id

        const direction =
            this.options.sortDesc.length === 0 || this.options.sortDesc[0]
                ? 'asc'
                : 'desc'
        const sort =
            this.options.sortBy.length === 0
                ? 'id'
                : _.filter(this.headers, { value: this.options.sortBy[0] })[0].value
        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        if (this.search && this.search !== '')
          filters.search = this.search

        await this.fetchListSeasons([filters, this.$toast])
      },
      async searchProduct(event) {
        this.loadings.products = true

        await this.fetchListProducts([{
          companyId: this.profile.company_id,
          search: event.target.value || "",
          pageableDTO: {
            page: 0,
            size: 10,
            sortBy: 'id',
            direction: 'ASC',
          }
        }, this.$toast]).finally(() => {
          this.loadings.products = false
        })
      },
      orderDatas(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      getLocationsByAction() {
        return _.filter(this.locationsList, { active: true })
      },
      clearLocations() {
        this.editedItem.primaryLocation = []
        this.editedItem.storageDevices = []
        this.$refs.form.resetValidation()
      },
      changeLocation() {
        if (!this.locations) {
          this.locations = []
        } else {
          this.clearLocations()
        }
      },
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox
        if (!this.showCheckBox) this.selectedItems = []
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      async exportCSVSeasons() {
        this.loadingExport = true
        const data = {
          language: LanguajeService.getLenguajeName(),
          seasons_ids: this.getByProperty(this.selectedItems, 'id'),
        }
        await this.exportCSV([data, this.$toast]).finally(() => {
          this.loadingExport = false
        })
      },
      async initialize() {
        this.profile = JSON.parse(localStorage.getItem('profile'))
        let promise = []
        promise.push(this.fetchListSeasons([this.defaultFilters, this.$toast]))
        promise.push(this.fetchStorageLocations([{
          stringFilter: "",
        }, this.$toast]))
      },
      selectAll(type, length, value, checkbox) {
        if (checkbox === false) {
          this.$nextTick(function () {
            switch (type) {
              case 'localization':
                this.checkboxState.localization = false
                break
            }
          })
          return []
        }
        let aux = []
        if (length != null) {
          _.forEach(length, (object) => {
            if (!_.find(value, (o) => {
              return o.id === object.id
            }))
              value.push(object)
          })

          aux = value
        } else {
          aux = value
        }
        return aux
      },
      checkState(prefix, total, values) {
        if (values.length === total.length) {
          switch (prefix) {
            case 'localization':
              this.checkboxState.localization = true
              break
          }
        } else {
          switch (prefix) {
            case 'localization':
              this.checkboxState.localization = false
              break
          }
        }
      },
      check(permisions) {
        return PermisionService.check(permisions)
      },
      showItem(item) {
        this.selectedItem = item
        this.editedIndex = this.seasons.indexOf(item)
        this.editedItem = Object.assign({}, item)
        if (item.evidences && item.evidences.length > 0) {
          this.getEvidences(this.editedItem)
        }
        this.showItemDetailsDialog = true
      },
      translate(key) {
        return LanguajeService.getKey3(key)
      },
      async editItem(item) {
          this.loading = true;
          this.selectedItem = item
          this.editedIndex = this.seasons.indexOf(item)
          this.editedItem = _.cloneDeep(item)

          this.loadings.products = true
          await this.fetchListProducts([{
          companyId: this.profile.company_id,
          search: this.translate(item.product.language_key) || "",
          pageableDTO: {
            page: 0,
            size: 10,
            sortBy: 'id',
            direction: 'ASC',
          }
          }, this.$toast]).finally(() => {
            this.loadings.products = false
          })


          this.$set(this.loadings, "primary", true)
          await this.fetchPrimaryLocations([
          {
            versionIds: [item.storageDevices[0].location_id],
            onlyCurrentVersion: false
          },this.$toast,
          ]).finally(() => {
            this.$set(this.editedItem, 'primaryLocation', item.storageDevices[0].location_id)
            this.$set(this.loadings, "primary", false)
          })

          this.$set(this.loadings, "secondary", true)
          await this.fetchStorageLocations([
          {
            versionIds: _.map(item.storageDevices, (s) => { return s.id }),
            onlyCurrentVersion: false
          },this.$toast])
          .finally(() => {
              this.$set(this.editedItem, 'storageDevices', item.storageDevices)
              this.$set(this.loadings, "secondary", false)
          })

          if (item.evidences && item.evidences.length > 0) {
            this.getEvidences(item)
          }

          this.dialog = true;
          this.loading = false;
      },
      downloadEvidence(item) {
        this.loadingDownloadEvidences = true
        SeasonsService.getEvidences(item)
          .then(response => {
            const FileSaver = require('file-saver')
            const blob = new Blob([response.data], {
              type: 'application/zip',
            })
            FileSaver.saveAs(blob, `season_${item.season_name}_evidences.zip`)

            this.$toast.success(
              i18n.t('general.notification.successDownloadEvidences'),
              {
                icon: 'mdi-check-circle',
                queueable: true
              }
            )
          })
          .catch(() => {
            this.$toast.error(
              i18n.t('general.notification.errorDownloadEvidences'),
              {
                icon: "mdi-alert-circle",
                queueable: true
              }
            )
          })
          .finally(() => {
            this.loadingDownloadEvidences = false
          })
      },
      getEvidences(editedItem) {
        const scope = this
        scope.loadingEvidences = true
        try {
          SeasonsService.getEvidences(editedItem)
            .then(response => {
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              const jsZip = require('jszip')
              jsZip.loadAsync(blob).then(zip => {
                Object.keys(zip.files).forEach(filename => {
                  zip.files[filename].async('blob').then(fileData => {
                    // These are your file contents
                    let type = ''
                    const nameSpliter = filename.split('.')
                    if (nameSpliter[1] === 'pdf' || nameSpliter[1] === 'PDF') {
                      type = 'application/pdf'
                    } else if (
                      nameSpliter[1] === 'xlsx' ||
                      nameSpliter[1] === 'XLSX'
                    ) {
                      type =
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    } else if (
                      nameSpliter[1] === 'xls' ||
                      nameSpliter[1] === 'XLS'
                    ) {
                      type = 'application/vnd.ms-excel'
                    } else {
                      type = 'image/' + nameSpliter[1]
                    }
                    scope.$store.commit(
                      'documents/SET_EVIDENCES',
                      new File([fileData], filename, { type: type })
                    )
                  })
                })
              })
            })
            .finally(() => {
              scope.loadingEvidences = false
            })
        } catch (error) {
          scope.$toast.error(
            i18n.t('general.notification.loadEvidencesError'),
            {
              queueable: true
            }
          )
        }
      },
      updateProductName(product) {
        this.selectedItem.product_name = product.product_name
      },
      updateStorageDevices(editedItem) {
        this.selectedItem.storageDevices = editedItem.storageDevices
      },
      deleteItem() {
        this.dialogClose()

        this.loading = true
        this.deleteSeason([this.deletingItem.id, this.$toast]).finally(() => {
          this.fetchListSeasons([this.defaultFilters, this.$toast])
          this.loading = false
        })
      },
      close() {
        if (this.dialog === true) this.dialog = false
        if (this.showItemDetailsDialog === true)
          this.showItemDetailsDialog = false

        this.$refs.form.reset()
        this.$refs.form.resetValidation()
        this.clearEvidences()

        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },
      save() {
        if (
          this.equalObjectsCompareRule(this.selectedItem, this.editedItem) &&
          this.editedItem.evidences.length === this.evidences.length
        ) {
          this.$toast.info(i18n.t('general.notification.nothingToModify'), {
            icon: 'mdi-information-slab-circle',
            queueable: true
          })
        } else {
          let item = Object.assign({}, this.editedItem)

          if(item.storageDevices && Array.isArray(item.storageDevices)) {
            item.storageDevices = item.storageDevices.map(s => ({ id: s.id }));
          }

          // Translate
          item.languageKey = LanguajeService.setKey3(
            item.season_name,
            item.languageKey,
            !item.id ? this.languageStructure : null
          )
          // *****
          item.companyId = this.profile.company_id
          item.startDateTime = moment(item.startDateTime)
            .toISOString()
            .split('.')[0]
          item.endDateTime = moment(item.endDateTime)
            .toISOString()
            .split('.')[0]

          this.saveSeason([
            item,
            this.evidences,
            this.editedIndex,
            this.$toast,
          ]).finally(() => {
            this.fetchListSeasons([this.defaultFilters, this.$toast])
          })
          this.close()
        }
      },
      dialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.showDialog = true
      },
      dialogClose() {
        this.showDialog = false
      },
      deleteItemDialog(item) {
        this.deletingItem = item
        this.dialogShow({
          title: i18n.t('general.titles.alert'),
          dialogText: i18n.t('general.notification.deleteItems'),
        })
      },
      getDateFormat(dateString) {
        return moment(new Date(dateString)).format('DD/MM/YYYY')
      },
      confirmDate(field) {
        this.refers[field] = false
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
      replaceSearch(event) {
        this.search = event.currentTarget.value.toString().replaceAll('"', '')
      },
      replaceSeasonName(event) {
        this.editedItem.season_name = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
      },
    },
    destroyed() {
      this.notification = false
    },
  }
</script>

<style lang="scss" scoped>
</style>
